<template>
  <div class="page">
    <h1><w-icon>mdi mdi-magnify</w-icon>仕組み と 効果</h1>
    <div class="content">
      <div class="image">
        <w-icon>mdi mdi-cards-playing-club-multiple-outline</w-icon><br>
        <w-icon>mdi mdi-axis-x-arrow</w-icon><br>
        <w-icon>mdi mdi-cards-playing-spade-outline</w-icon>
        <w-icon>mdi mdi-cards-playing-heart-outline</w-icon>
      </div>
      <h2>
        <w-icon>mdi mdi-check-bold</w-icon>
        斬新だけど 緻密な構造
      </h2>
      <w-divider/>
      <p>
        &emsp;リングの途中が<u>二又に分かれている</u>といった形状をしている「わけりんぐ」は、
        カードを仕分けるためのしなやかな柔軟性と、カードを強固に保持するための堅牢さ
        の両方が共に両立するように、斬新な構造の採用と緻密な構造解析によって実現されました。
      </p>
      <h2>
        <w-icon>mdi mdi-check-bold</w-icon>
        保持したまま 分けられる
      </h2>
      <w-divider/>
      <p>
        &emsp;カードをリングで保持したまま、仕分けることができます。
        扱い方はとても簡単で、カードをめくった際に、分けたい方向に向ってカードを倒しながら進めることで、驚くほど簡単にカードを仕分けることがきます。
        <w-flex class="justify-space-evenly  align-center">
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs5" src="/image/page/item/wake-ring/merit/right.jpg"></w-image>
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs5" src="/image/page/item/wake-ring/merit/left.jpg"></w-image>
        </w-flex>
        &emsp;従って、表に問題が裏に正解が書かれた学習カードを「わけりんぐ」で扱うと、学習カードによる学習を行いながら、
        <u>不正解だったカードだけを、簡単にひとつにまとめる</u>ことができるようになります。
        つまり、「わけりんぐ」で扱った学習カードは、学習済みのカード束と未学習のカード束が、常に分かれている状態となります。
        <br>
        &emsp;そのため、学習者にとって、次に取り組むべき問題とその量が常に明確となり、
        <w-card class="bdrs3" title-class="blue-light5--bg" content-class="text-center">
          <template #title>
            <w-icon>mdi mdi-chart-timeline-variant-shimmer</w-icon>
            <span class="ml2">効果</span>
          </template>
          <span class="title2"><b>学習意欲 の 継続的な向上</b></span>
        </w-card>
        が、期待できるようになります。
        <br>&emsp;<router-link to="/item/wake-ring/how-to-use/">ＣＧシミュレーション</router-link>
        をご覧頂けますと、「わけりんぐ」の軽快な操作性が一目瞭然でおわかりいただけることと思います。
      </p>
      <h2>
        <w-icon>mdi mdi-check-bold</w-icon>
        仕分けた束を 戻せる
      </h2>
      <w-divider/>
      <p>
        &emsp;仕分けたカードの束のうち、右の束または左の束を、仕分ける前の位置に戻すことができます。
        こちらも扱い方は簡単で、カードの束をまとめて持ち、カードの縁で分岐点を押し広げるようにして斜めに進めることで、カードの束を仕分ける前の位置にまで、まとめて戻すことができます。
        <w-flex class="justify-space-evenly  align-center">
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs5" src="/image/page/item/wake-ring/merit/back.jpg"></w-image>
          <w-image tag="img" width="100%" style="max-width: 300px" class="bdrs5" src="/image/page/item/wake-ring/merit/relearning.jpg"></w-image>
        </w-flex>
        &emsp;つまり、「わけりんぐ」で学習カードを扱うと、<u>不正解だったカードだけを即座に集中的に再学習</u>することができるようになります。
        <br>&emsp;そのため、学習者は、必要な再学習だけを常に集中して行うことができるため、
        <w-card class="bdrs3" title-class="blue-light5--bg" content-class="text-center">
          <template #title>
            <w-icon>mdi mdi-chart-timeline-variant-shimmer</w-icon>
            <span class="ml2">効果</span>
          </template>
          <span class="title2"><b>学習効率 と 学習効果 の 継続的な向上</b></span>
        </w-card>
        が、期待できるようになります。
        <br>&emsp;<router-link to="/item/wake-ring/movie/">動画</router-link>
        をご覧頂けますと、「わけりんぐ」を利用した効果的な学習方法の詳細が、おわかりいただけることと思います。
      </p>
      <h2>
        <w-icon>mdi mdi-check-bold</w-icon>
        強固に ロックできる
      </h2>
      <w-divider/>
      <p>
        &emsp;仕分ける前のカードや仕分け終えたカードを、いつでも強固にロックすることができます。
        こちらも扱い方は簡単で、ストラップ部のパーツをリングの先端にはめ込むことで、すべてのカードが外れないようにロックされます。
        <br>&emsp;つまり、ロック機能を活用することで、「わけりんぐ」で学習中のカードが外れてこぼれ落ちてしまう心配をする必要はなく、
        思い立った時に<u>即座に学習を始めて、即座に学習を中断する</u>ことができるようになっています。
        <br>&emsp;そのため 学習者は、お手持ちの鞄やふでばこなどに学習したいカードをセットした「わけりんぐ」を入れることで、
        <w-card class="bdrs3" title-class="blue-light5--bg" content-class="text-center">
          <template #title>
            <w-icon>mdi mdi-chart-timeline-variant-shimmer</w-icon>
            <span class="ml2">効果</span>
          </template>
          <span class="title2"><b>持ち歩きながら すきま時間に 学習できる</b></span>
        </w-card>
        と、いった効果が期待できるようになります。
        <br>&emsp;<router-link to="/item/wake-ring/movie/">動画</router-link>
        をご覧頂けますと、「わけりんぐ」を使った学習の軽快な操作性がおわかりいただけることと思います。
        <w-divider/>
      </p>
      <div class="next-link">
        <w-flex align-center justify-space-evenly>
          <router-link to="/item/wake-ring/how-to-use/">ＣＧシミュレーション</router-link>
          <router-link to="/item/wake-ring/movie/">動画</router-link>
          <router-link to="/item/wake-ring/research/">有効性</router-link>
          <a href="https://minne.com/@mihiraku" target="_blank" rel="noopener noreferrer">ショップ <w-icon>mdi mdi-open-in-new</w-icon></a>
        </w-flex>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.content {
  .image {
    padding: 30px 0;
    .w-icon {
      font-size: 300%;
      margin: 0 13px;
    }
    span {
      font-size: 300%;
      line-height: 200%;
    }
  }
}
</style>
